@font-face {
  font-family: 'Avenir';
  src: url('fonts/AvenirLight/Avenir-Light.woff2') format('woff2'),
       url('fonts/AvenirLight/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('fonts/AvenirMedium/AvenirMedium.woff2') format('woff2'),
      url('fonts/AvenirMedium/AvenirMedium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('fonts/AvenirHeavy/AvenirHeavy.woff2') format('woff2'),
      url('fonts/AvenirHeavy/AvenirHeavy.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('fonts/IntegralCFMedium/IntegralCF-Medium.woff2') format('woff2'),
       url('fonts/IntegralCFMedium/IntegralCF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html, body {
  overflow-x: hidden;
  overflow-y: scroll;
  color: rgb(236, 233, 220);
  background-color: rgba(20, 13, 14, 1);
  /* overflow-y: scroll;
  scroll-snap-type: y proximity;
  scroll-padding: 50px 0 0 0;
  scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#pause {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: 50px;
  left: 7.5px;
  font-size: 20px;
  background-color: rgb(236, 233, 220);
  cursor: pointer;
  padding: 5px 10px;
  border: 2px solid rgb(146, 143, 130);
  color: rgb(106, 103, 90);
}

#pauseIcon {
  margin: -1.5px 0;
}

#pause span {
  font-family: "Avenir Medium";
  font-size: 17px;
  padding-left: 5px;
  margin-top: 1px;
}

#resume {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: 50px;
  left: 7.5px;
  display: none;
  font-size: 18px;
  background-color: rgb(236, 233, 220);
  cursor: pointer;
  padding: 5px 10px;
  width: 87px;
  height: 41px;
  border: 2px solid rgb(146, 143, 130);
  color: rgb(106, 103, 90);
}

#playIcon {
  margin-top: 0px;
}

#resume span {
  font-family: "Avenir Medium";
  font-size: 17px;
  padding-left: 5px;
  margin-top: 2px;
}

#pause:hover {
  /* background-color: rgb(196, 193, 180); */
  color: rgb(20, 13, 14);
  border: 2px solid rgb(20, 13, 14);
}

#resume:hover {
  /* background-color: rgb(196, 193, 180); */
  color: rgb(20, 13, 14);
  border: 2px solid rgb(20, 13, 14);
}

@media only screen and (min-width: 976px) and (min-height: 820px) {
  #pause span {
    font-family: "Avenir Medium";
    font-size: 30px;
    padding-left: 10px;
    margin-top: 5px;
  }

  #resume span {
    font-family: "Avenir Medium";
    font-size: 30px;
    padding-left: 10px;
    margin-top: 5px;
  }

  #pause {
    font-size: 30px;
    padding: 10px 20px;
  }

  #resume {
    font-size: 32px;
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
  }
}



/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- ABOUT --------------- */

/* ----- PERSONNEL ----- */

/* Position Sections */

.positions {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  padding: 45px 0 0 0;
  width: 100%;
}

/* Invisible Placeholder */

.placeholder {
  width: 196px;
  flex: 1 0 auto;
  padding: 20px;
  margin: 8px 0;
  z-index: 1;
}

/* Biography Modal */

#bioBoxBackground {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: rgba(236, 233, 220, 0);
  backdrop-filter: blur(0px);
  opacity: 1;
  pointer-events: none;
}

#bioBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(236, 233, 220, 1);
  width: calc(100% - 40px);
  backdrop-filter: blur(8px);
  color: black;
  box-shadow: 0 0 20px rgb(20, 13, 14);
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

/* Filters */

#personnelByName {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 251px;
  height: 19px;
  font-family: 'Avenir';
  font-size: 16px;
  border: none;
  outline: none
}

#personnelByRole {
  display: flex;
  align-items: center;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  width: 274px;
  height: 26px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#personnelByAffiliation {
  display: flex;
  align-items: center;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  width: 231px;
  height: 26px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#personnelByName:focus, #personnelByRole:focus, #personnelByAffiliation:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#resetFilters {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#resetFilters:hover {
  color: red
}

#resetFilters:focus {
  color: purple;
}

/* Media Queries */

@media only screen and (max-width: 503px) {
  .positions {
    justify-content: center;
  }
}

@media only screen and (max-width: 487px) {
  .positions {
    margin: 20px 0 0 0;
    padding: 30px 0 0 0;
  }
}

@media only screen and (max-width: 271px) {
  .positions {
    padding: 20px 0 10px 0;
  }
}

@media only screen and (max-width: 499px) {
  #personnelByName {
    width: 190px;
    margin: auto;
  }

  #personnelByRole {
    width: 200px;
    margin: auto;
  }

  #personnelByAffiliation {
    width: 200px;
    margin: auto;
  }

  #resetFilters {
    left: 5px;
    right: auto
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------------- */



/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- ABOUT --------------- */

/* ----- COLLABORATORS ----- */

#collaboratorsByName {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 248.5px;
  height: 19px;
  font-family: 'Avenir';
  font-size: 16px;
  border: none;
  outline: none
}

#collaboratorsByType {
  display: flex;
  align-items: center;
  width: 269px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#byName:focus, #byType:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#collaboratorsResetFilters {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#collaboratorsResetFilters:hover {
  color: red
}

#collaboratorsResetFilters:focus {
  color: purple;
}

/* Invisible Placeholder */

.collaboratorsPlaceholder {
  width: 226px;
  flex: 1 0 auto;
  padding: 20px;
  margin: 8px 0;
  z-index: 1; 
}

/* Media Queries */

@media only screen and (max-width: 499px) {
  #collaboratorsByName {
    width: 190px;
    margin: auto;
  }

  #collaboratorsByType {
    width: 200px;
    margin: auto;
  }

  #collaboratorsResetFilters {
    left: 5px;
    right: auto
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------------- */


/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- ABOUT --------------- */

/* ----- PRESS ----- */

#pressByTitle {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 281px;
  height: 19px;
  font-family: 'Avenir';
  font-size: 16px;
  border: none;
  outline: none
}


#pressByNewsSource {
  display: flex;
  align-items: center;
  width: 220px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#pressByTopic {
  display: flex;
  align-items: center;
  width: 283.5px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;

}

#pressByTitle:focus, #pressByNewsSource:focus, #pressByTopic:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#pressResetFilters {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#pressResetFilters:hover {
  color: red
}

#pressResetFilters:focus {
  color: purple;
}

/* Invisible Placeholder */

.pressPlaceholder {
  width: 206px;
  flex: 1 0 auto;
  padding: 20px;
  margin: 8px 0;
  z-index: 1; 
}

/* Media Queries */

@media only screen and (max-width: 499px) {

  #pressByTitle {
    width: 190px;
    margin: auto;
  }

  #pressByNewsSource {
    width: 200px;
    margin: auto;
  }

  #pressByTopic {
    width: 200px;
    margin: auto;
  }

  #pressResetFilters {
    left: 5px;
    right: auto
  }
  
}


/* ---------------------------------------------------------------------------------------------------------------------------------- */


/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- ABOUT --------------- */

/* ----- PRESS ----- */

#visualMediaByTitle {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 281px;
  height: 19px;
  font-family: 'Avenir';
  font-size: 16px;
  border: none;
  outline: none
}


#visualMediaByType {
  display: flex;
  align-items: center;
  width: 232px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#visualMediaByTopic {
  display: flex;
  align-items: center;
  width: 283.5px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;

}

#visualMediaByTitle:focus, #visualMediaByType:focus, #visualMediaByTopic:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#visualMediaResetFilters {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#visualMediaResetFilters:hover {
  color: red
}

#visualMediaResetFilters:focus {
  color: purple;
}

/* Invisible Placeholder */

.visualMediaPlaceholder {
  width: 206px;
  flex: 1 0 auto;
  padding: 20px;
  margin: 8px 0;
  z-index: 1; 
}

/* Media Queries */

@media only screen and (max-width: 499px) {

  #visualMediaByTitle {
    width: 190px;
    margin: auto;
  }

  #visualMediaByType {
    width: 200px;
    margin: auto;
  }

  #visualMediaByTopic {
    width: 200px;
    margin: auto;
  }

  #visualMediaResetFilters {
    left: 5px;
    right: auto
  }
  
}

/* ---------------------------------------------------------------------------------------------------------------------------------- */


/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- RESEARCH --------------- */

/* ----- PUBLICATIONS ----- */

#publicationsByTitle {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 270.5px;
  height: 19px;
  font-family: "Avenir";
  font-size: 16px;
  border: none;
  outline: none
}
  
#publicationsByAuthor {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 249.5px;
  height: 19px;
  font-family: "Avenir";
  font-size: 16px;
  border: none;
  outline: none
}
  
#publicationsByTopic {
  display: flex;
  align-items: center;
  width: 273px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}
  
#publicationsByTitle:focus, #publicationsByAuthor:focus, #publicationsByTopic:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#publicationsResetFilters {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#publicationsResetFilters:hover {
  color: red
}

#publicationsResetFilters:focus {
  color: purple;
}

/* Media Queries */

@media only screen and (max-width: 499px) {

  #publicationsByTitle {
    width: 190px;
    margin: auto;
  }

  #publicationsByAuthor {
    width: 190px;
    margin: auto;
  }

  #publicationsByTopic {
    width: 200px;
    margin: auto;
  }

  #publicationsResetFilters {
  left: 5px;
  right: auto
  }

}

/* ---------------------------------------------------------------------------------------------------------------------------------- */


/* ---------------------------------------------------------------------------------------------------------------------------------- */

/* --------------- RESEARCH --------------- */

/* ----- SOFTWARE ----- */

#softwareByName {
  margin: 0 20px 0px 10px;
  padding: 4px 5px 3px 5px;
  width: 255px;
  height: 19px;
  font-family: 'Avenir';
  font-size: 16px;
  border: none;
  outline: none
}

#softwareByTopic {
  display: flex;
  align-items: center;
  width: 271px;
  height: 26px;
  margin: 0 20px 0px 10px;
  padding: 0px 30px 0px 5px;
  font-family: "Avenir";
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  border-radius: 0;
}

#softwareByName:focus, #softwareByTopic:focus {
  border: 1px solid rgb(20, 13, 14);
  height: 27px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
}

#softwareResetFilters {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  font-size: 14px;
}

#softwareResetFilters:hover {
  color: red
}

#softwareResetFilters:focus {
  color: purple;
}

/* Media Queries */

@media only screen and (max-width: 499px) {

  #softwareByName {
    width: 190px;
    margin: auto;
  }

  #softwareByTopic {
    width: 200px;
    margin: auto;
  }

  #softwareResetFilters {
    left: 5px;
    right: auto
  }

}